import styled from "styled-components";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import { getAnchorStyles } from "@website-builder/utilities/utils/DesignUtils.js";
export const ImageWrapper = styled.div`
	.media-with-text-image {
		object-fit: cover;
		max-width: 100%;
		border-radius: 8px;
	}
	z-index: 1;
	position: relative;
`;

export const MediaWithTextWrapper = styled.div`
	padding: 80px 0;
	position: relative;
	overflow: hidden;
	${({ isTwoColumnSectionContent }) =>
		isTwoColumnSectionContent
			? `padding-bottom: 0px;
     .video-container {
        width: 100%;
     }
    & > div {
      margin-right: 0px;
    }
  `
			: null}

	.link svg > path {
		fill: ${({ isDarkBackground, linkColor }) =>
			linkColor
				? linkColor
				: isDarkBackground
					? "var(--secondary-purple-100)"
					: "var(--secondary-purple-300)"};
	}
	p.link-text {
		color: ${({ isDarkBackground, linkColor }) =>
			linkColor
				? linkColor
				: isDarkBackground
					? "var(--secondary-purple-100)"
					: "var(--secondary-purple-300)"};
	}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding: 30px 0px;
		${({ isTwoColumnSectionContent }) =>
			isTwoColumnSectionContent
				? ` & > div {
        margin-right: 12px;
      }
      .grid-wrapper {
        margin: 0 12px;
      }
      `
				: null}
	}
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	position: relative;

	.rich-text-wrapper {
		${({ isDarkBg }) => getAnchorStyles(isDarkBg)}
	}
`;

export const VideoWrapper = styled.div`
	height: 100%;
	.media-with-text-image img {
		object-fit: cover;
		width: 100%;
		border-radius: 8px;
	}
	position: relative;
`;
